import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BaseService } from '../../Services/base.service';
import { ServiceLocator } from '../../Services/locator.service';
import { BizSelectComponent } from '../biz-select/biz-select.component';

@Component({
    
    selector: 'employee-info-basic',
    templateUrl: 'employee-info-basic.component.html',
    
})
export class EmployeeInfoBasicComponent implements OnInit {

    @Input() employeeID: number = 0;

    empForm = new FormGroup({
        employeeID: new FormControl(),
        fullName: new FormControl(),
        oldCardNo: new FormControl(),
        department: new FormControl(),
        designation: new FormControl(),
        jdate: new FormControl(),
        cdate: new FormControl(),
        group: new FormControl(),
        type: new FormControl(),
        category: new FormControl(),
    });

    employeeParams: any[] = [];
    employeeData: any[] = [];

    @ViewChild('empSelect') empSelect: BizSelectComponent;

    private baseService: any;
    data: any;

    constructor() {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    async ClearForm() {
        Object.keys(this.empForm.controls).map(el => {
            let ctr = this.empForm.get(el);
            if (ctr) {
                this.empForm.controls[el].setValue(undefined);
                ctr.markAsUntouched({ onlySelf: true });
                ctr.markAsPristine({ onlySelf: true });
            }
        })
    }

    async displayInfo(id, e) {

        if (id > 0 && this.empSelect && this.empSelect.data && this.empSelect.data.length > 0) {

            let obj = this.empSelect.data.filter(o => o.employeeID === Number(id))[0];
            if (obj) {
                this.empForm.controls['employeeID'].setValue(obj.employeeID)
                this.empForm.controls['oldCardNo'].setValue(obj.oldCardNo)
                this.empForm.controls['department'].setValue(obj.department)
                this.empForm.controls['designation'].setValue(obj.designation)
                this.empForm.controls['cdate'].setValue(obj.cdate)
                this.empForm.controls['jdate'].setValue(obj.jdate)
                this.empForm.controls['type'].setValue(obj.type)
                this.empForm.controls['group'].setValue(obj.group)
                this.empForm.controls['category'].setValue(obj.category)
            }
        }
    }

    async ngOnInit() {
        this.employeeParams = [
            { name: 'tType', value: '0' },
            { name: 'flocation', value: localStorage.getItem('LocationID') },
            { name: 'USerID', value: localStorage.getItem('UserID') },
            { name: 'filed', value: 'fullName' },
            { name: 'value', value: 'value' }
        ]
    }
}
